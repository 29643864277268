import moment from "moment-timezone";

export function getDate(date, timezone) {
  const d = moment.tz(date, timezone);
  return d.format("dddd") + ", " + d.format("MMMM") + " " + d.format("DD");
}

export function getTimes(startTime, endTime, timezone) {
  const start = moment.tz(startTime.replace("Z", ""), timezone);
  const end = moment.tz(endTime.replace("Z", ""), timezone);

  return (
    start.format("h") +
    start.format("A") +
    " - " +
    end.format("h") +
    end.format("A")
  );
}
