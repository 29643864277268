import React, { Component } from "react";

import EventTile from "@components/global/tiles/EventTile";
import { STANDARD_TILE, HERO_TILE } from "@constants/content-tile-types";
import OptionSelect from "@components/form/option-select";
import { isWindowDefined } from "../../../assets/third-party/blast";

class FeaturedModule extends Component {
  getCurrentRegion = () => {
    if (isWindowDefined && sessionStorage.getItem("chosen_region")) {
      return sessionStorage.getItem("chosen_region");
    }

    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "select";
  };

  handleChange = (e) => {
    sessionStorage.setItem("chosen_region", e.value);
    const { onChange } = this.props;
    onChange({
      type: "region",
      value: e.value,
      selected: true,
    });
  };

  renderEvents = () => {
    const { events } = this.props;
    const eventsArr = events.map((event, i) => {
      if (i === 0) {
        return (
          <EventTile
            key={String(event.id + i)}
            type={HERO_TILE}
            column="col s12"
            categoryFilters={this.props.categoryFilters}
            {...event}
          />
        );
      }
      return (
        <EventTile
          key={String(event.id + i)}
          type={STANDARD_TILE}
          column="col s12 m6 l4 xl3"
          categoryFilters={this.props.categoryFilters}
          {...event}
        />
      );
    });

    return eventsArr;
  };

  render() {
    return (
      <div className="container container__featured">
        <div className="row">
          <div className="col s12 m6">
            <div className="heading heading--primary heading--intro">
              <h1 className="heading__headline">Restless Nites</h1>
              <span className="heading__divider" />
              <p className="heading__copy">
                is a curated nightlife guide & ticketing platform for the next
                generation of events.
              </p>
            </div>
          </div>
          <div className="col s12 m6">
            <div className="heading heading--primary header--selection">
              {this.props.regions && (
                <div className="featured__selection">
                  <span className="select__prompt">See Events In</span>
                  <OptionSelect
                    type="regionSelect"
                    editable={Boolean(true)}
                    required={Boolean(false)}
                    name="region"
                    initialValue={this.getCurrentRegion()}
                    options={this.props.regions}
                    defaultOption={{ value: "select", label: "Select" }}
                    handleChange={this.handleChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedModule;
