import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import { configureHeader } from "../actions/ui";
import { updateFilter } from "../actions/update-filter";
import Carousel from "@components/global/carousel/carousel";
import FeaturedModule from "@components/modules/landing/featured-module";
import Footer from "@components/global/footer/footer";
import {
  PROMOTE_WITH_URL,
  TICKET_WITH_URL,
  TTF_ADMIN_HOST,
} from "@constants/config";

import {
  EVENT_GRADE_LANDING_CAROUSEL,
  EVENT_GRADE_FEATURED,
  EVENT_GRADE_RECOMMENDED,
  EVENT_STATE_PUBLISHED,
  EVENT_CAROUSEL_MAX,
  META,
} from "../constants/config";
import { getActiveLocations, currentLocation } from "@selectors/filters";
import FilteredEvents from "@assets/svg/svgui/filterevents.svg";
import NewsLetter from "@assets/svg/svgui/newsletter.svg";
import Curators from "@assets/svg/svgui/curators.svg";
import Faq from "@assets/svg/svgui/faq.svg";
import SellTicket from "@assets/svg/svgui/sellticket.svg";
import Promote from "@assets/svg/svgui/promote.svg";
import SubmitEvent from "@assets/svg/svgui/submitevent.svg";
import EditorialFeaturedModule from "@components/modules/common/top-editorial-module";

class Landing extends Component {
  componentDidMount = () => {
    const { configureHeader, platform } = this.props;
    window && window.scrollTo(0, 0);
    configureHeader({
      headerVisibilityLocked: platform.IS_MOBILE,
    });
  };

  getContent = (grade, total) => {
    const {
      events,
      currentRegion,
    } = this.props;

    const regionalContent = events.filter((event) => {
      return event.region === currentRegion.value;
    });

    if (grade === "featured") {
      return regionalContent
        .filter((event) => {
          return (
            event.event_grade >= EVENT_GRADE_FEATURED &&
            event.event_state === EVENT_STATE_PUBLISHED
          );
        })
        .slice(0, total);
    } else if (grade === "recommended") {
      return regionalContent
        .filter((event) => {
          return (
            event.event_grade >= EVENT_GRADE_RECOMMENDED &&
            event.event_grade < EVENT_GRADE_FEATURED &&
            event.event_state === EVENT_STATE_PUBLISHED
          );
        })
        .slice(0, total);
    } else if (grade === "carousel") {
      const r = regionalContent
        .sort((a, b) => b.event_grade - a.event_grade)
        .filter((event) => {
          return (
            event.event_grade >= EVENT_GRADE_LANDING_CAROUSEL &&
            event.event_state === EVENT_STATE_PUBLISHED
          );
        })
        .slice(0, total);

      return r;
    }

    return regionalContent
      .filter((event) => {
        return event.event_state === EVENT_STATE_PUBLISHED;
      })
      .slice(0, total);
  };

  render() {
    console.log('netlify')
    const { updateFilter, children, regions, currentRegion } = this.props;

    return (
      <div className="landing-container">
        {children}
        <div className="landing-carousel">
          <Carousel slides={this.getContent("carousel", EVENT_CAROUSEL_MAX)} />
        </div>

        <FeaturedModule
          regions={regions}
          currentRegion={currentRegion}
          onChange={updateFilter}
        />

        <div className="container__guidedtour--user">
          <div className="container">
            <div className="row">
              <div className="col s12 m12 col--no-margin-bottom">
                <div className="row">
                  <div className="col s12 col--no-margin-bottom">
                    <div className="split-content__top-divider" />
                  </div>
                  <div className="col s12 m4 heading heading--secondary">
                    <h2 className="heading__headline">
                      <span>Plan Your Nite</span>
                    </h2>
                    <p className="heading__description">
                      Cut through the noise of nightlife & find your groove. Let
                      our Curators & our Weekly Newsletter guide you, or filter
                      our offerings to fit your taste.
                    </p>
                  </div>
                  <div className="col s12 m8 col--no-margin-bottom">
                    <div className="row">
                      <div className="col s12 m6">
                        <Link
                          to="/events"
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <FilteredEvents
                                fill="#F08057"
                                style={{ width: 48, height: 48 }}
                                className="quicklink-icon-svg"
                              />
                            </span>
                            <span>Find & Filter Events</span>
                          </h2>
                        </Link>
                      </div>
                      <div className="col s12 m6">
                        <a
                          href="#footer"
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <NewsLetter
                                style={{ width: 48, height: 48 }}
                                className="quicklink-icon-svg"
                                fill="#F08057"
                              />
                            </span>
                            <span>Get The Newsletter</span>
                          </h2>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12 m6">
                        <Link
                          to="/curators"
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <Curators
                                style={{ width: 48, height: 48 }}
                                className="quicklink-icon-svg"
                                fill="#F08057"
                              />
                            </span>
                            <span>Meet Our Curators</span>
                          </h2>
                        </Link>
                      </div>
                      <div className="col s12 m6">
                        <a
                          href="https://support.restlessnites.com/hc/en-us/categories/4449766007323-Event-Attendees"
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <Faq
                                className="quicklink-icon-svg"
                                style={{ width: 48, height: 48 }}
                                fill="#F08057"
                              />
                            </span>
                            <span>Frequently Asked</span>
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container__guidedtour--promoter">
          <div className="container">
            <div className="row">
              <div className="col s12 m12 col--no-margin-bottom">
                <div className="row">
                  <div className="col s12 col--no-margin-bottom">
                    <div className="split-content__top-divider" />
                  </div>
                  <div className="col s12 m4 heading heading--secondary">
                    <h2 className="heading__headline">
                      <span>Boost Your Event</span>
                    </h2>
                    <p className="heading__description">
                      There's a thousand things to do on any given night. Make
                      sure the <em>right crowd</em> finds their way to your
                      event.
                    </p>
                  </div>
                  <div className="col s12 m8 col--no-margin-bottom">
                    <div className="row">
                      <div className="col s12 m6">
                        <a
                          href={TICKET_WITH_URL}
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <SellTicket
                                className="quicklink-icon-svg"
                                style={{ width: 48, height: 48 }}
                                fill="#F08057"
                              />
                            </span>
                            <span>Ticket with RN</span>
                          </h2>
                        </a>
                      </div>
                      <div className="col s12 m6">
                        <a
                          href={PROMOTE_WITH_URL}
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <Promote
                                className="quicklink-icon-svg"
                                style={{ width: 48, height: 48 }}
                                fill="#F08057"
                              />
                            </span>
                            <span>Promote with RN</span>
                          </h2>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12 m6">
                        <a
                          href={`${TTF_ADMIN_HOST}/events/new`}
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon fix-stroke">
                              <SubmitEvent
                                className="quicklink-icon-svg fix-stroke-svg"
                                style={{ width: 48, height: 48 }}
                                fill="#F08057"
                              />
                            </span>
                            <span>Submit Your Event</span>
                          </h2>
                        </a>
                      </div>
                      <div className="col s12 m6">
                        <a
                          href="https://support.restlessnites.com/hc/en-us/categories/4449796307739-Event-Organizers"
                          className="guidedtour--quicklink with-icon"
                        >
                          <h2 className="menu__item-name">
                            <span className="quicklink-icon">
                              <Faq
                                className="quicklink-icon-svg"
                                style={{ width: 48, height: 48 }}
                                fill="#F08057"
                              />
                            </span>
                            <span>Frequently Asked</span>
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditorialFeaturedModule
          browser={this.props.browser}
          posts={this.props.editorial}
          users={this.props.users}
          squarePreviews={true}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
    platform: state.platform,
    editorial: state.editorial.data,
    events: state.events.carousel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
  };
};

const LandingRedux = connect(mapStateToProps, mapDispatchToProps)(Landing);

export default LandingRedux;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <title>{META.title}</title>
    <link rel="canonical" href={process.env.GATSBY_TTF_SITE_URL} />
  </>
);
