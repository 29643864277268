import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider from 'react-slick';
import CarouselItem from './carousel-item';

class Carousel extends Component {

  static propTypes = {
    slides: PropTypes.array
  }

  renderSlides = (slides) => {
    return slides.sort((a, b) => b.event_grade - a.event_grade).map((slide, i) => {
			return (
        <div key={ String(slide.id) }>
          <CarouselItem {...slide} />
        </div>
      );
		});
  }

  render() {
    const { slides } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000
    };

    return (
      <div className="slick-slider-wrapper">
      { slides.length > 0 &&
        <Slider {...settings}>
          { this.renderSlides(slides) }
        </Slider>
      }
      </div>
    );
  }
}


export default Carousel;
