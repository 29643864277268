import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "gatsby";

import { getDate, getTimes } from "../../../utils/date-formatter";

const specialPages = [
  "cf46bf3dca7c691a9137",
  "b85cb4cc0d3b99f58ed9",
  "913523328789265",
  "cloak",
];

class CarouselItem extends Component {
  static propTypes = {
    square: PropTypes.object,
    banner: PropTypes.object,
    id: PropTypes.string,
    slug: PropTypes.string,
    date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    timezone: PropTypes.string,
    name: PropTypes.string,
    brief: PropTypes.string,
  };

  render() {
    const { square, banner, name, timezone, id, bannerImage, flyerImage } =
      this.props;

    const startTime = this.props.start_time;
    const endTime = this.props.end_time;
    let slideImage = "";
    if (bannerImage || flyerImage) {
      slideImage = bannerImage || flyerImage;
    } else if (banner) {
      slideImage = banner.url;
    } else if (square) {
      slideImage = square.url;
    }

    const isSpecial =
      specialPages.includes(this.props.slug) ||
      specialPages.includes(this.props.id)
        ? true
        : false;

    return (
      <Link
        to={"events/" + `${this.props.slug ? this.props.slug : this.props.id}`}
      >
        <div
          key={id}
          className="carousel-slide"
          style={{ backgroundImage: "url(" + slideImage + ")" }}
        >
          <div className="hero-gradient-overlay" />
          <div className="container container__carousel-item">
            <div className="row">
              <div className="col s12 m10 l9 xl9">
                <div className="carousel-item__details">
                  {isSpecial ? (
                    <div className="carousel-item__date special">
                      {"FRI, OCT 20 & SAT, OCT 21 • "}
                      <span className="carousel-item__date--time">
                        {"5PM - 3AM (FESTIVAL)"}
                      </span>
                    </div>
                  ) : (
                    <div className="carousel-item__date">
                      {startTime && timezone
                        ? getDate(startTime, timezone) + " • "
                        : ""}
                      <span className="carousel-item__date--time">
                        {startTime && endTime && timezone
                          ? getTimes(startTime, endTime, timezone)
                          : ""}
                      </span>
                    </div>
                  )}

                  <div className="carousel-item__title">{name}</div>
                  {isSpecial && (
                    <div className="carousel-item__subtitle">
                      {
                        "ft. The Jesus & Mary Chain, KMFDM, Poptone, She Wants Revenge, Com Truise, TR/ST & many more..."
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default CarouselItem;
